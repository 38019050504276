<template>
  <div class="loginPage">
    <img
      src="@/assets/logo.spedido.nobg.png"
      style="max-width: 100%"
      class="logo"
    />
    <div class="login p-2 p-sm-3 p-md-5">
      <div class="loginFormCenter">
        <b-form
          @submit="login"
          @submit.stop.prevent
        >
          <h4>Bem vindo(a) de volta</h4>

          <b-form-group class="mt-5">
            <b-input
              placeholder="Usuario"
              v-model="form.usuario"
              :state="validateState('usuario')"
              @change="() => (form.usuario = form.usuario.toUpperCase())"
              ref="usuario"
            ></b-input>
            <b-form-invalid-feedback
              >Informe um nome de usuário válido</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group class="mt-5">
            <b-input
              placeholder="Senha"
              type="password"
              ref="senha"
              v-model="form.senha"
              :state="validateState('senha')"
            ></b-input>
            <b-form-invalid-feedback>Informe uma senha</b-form-invalid-feedback>
          </b-form-group>
          <b-button
            variant="primary"
            type="submit"
            size="lg"
            class="mt-5"
            block
            @click="login"
            :disabled="loading"
          >
            <span v-if="!loading"> <i class="fa fa-"></i> Acessar </span>
            <span v-else> <b-spinner size="small" /> aguarde... </span>
          </b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/scss/login.scss";
import delay from "delay";

// import javascriptBarcodeReader from "javascript-barcode-reader";

export default {
  components: {},
  data() {
    return {
      recursos: [],
      form: {
        usuario: "",
        senha: "",
      },
      validator: [],
      retorno: {
        type: "success",
        msg: "",
      },
      loading: false,
      bkpATIVO: window.location.href.indexOf("#BKP") >= 0,
    };
  },
  async mounted() {
    // Grab elements, create settings, etc.
    // var video = document.getElementById("video");
    // // Get access to the camera!
    // if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    //   // Not adding `{ audio: true }` since we only want video now
    //   navigator.mediaDevices
    //     .getUserMedia({ video: true })
    //     .then(function (stream) {
    //       //video.src = window.URL.createObjectURL(stream);
    //       video.srcObject = stream;
    //       video.play();
    //     });
    // }

    this.recursos = [];
    await delay(800);
    for (let rec of this.$store.state.menu.filter(
      (x) => x.exibe && x.nome != "Sair"
    )) {
      await delay(300);
      this.recursos.push(rec);
    }
    this.$refs.usuario.focus();
    this.$store.state.loading = false;
  },
  methods: {
    openBKP() {
      window.open("./login#BKP");
    },
    // t() {
    //   var video = document.getElementById("video");
    //   var canvas = document.getElementById("canvas");
    //   var context = canvas.getContext("2d");

    //   let image = context.drawImage(video, 0, 0, 640, 480);
    //   console.log('image', image, video);
    //   console.log('image tp', typeof image);

    //   javascriptBarcodeReader({
    //     image: canvas,
    //     barcode: "code-2of5",
    //     barcodeType: "interleaved",
    //   })
    //     .then((result) => {
    //       document.getElementById("tst").innerHTML = "RESULT: " + result;
    //       console.log("RESULT", result);
    //     })
    //     .catch((er) => {
    //       console.log(er);
    //     });
    // },
    login(evt) {
      evt.preventDefault();

      // console.log(1);
      this.validator = [];
      if (this.form.senha.trim() == "") {
        this.validator.push({ key: "senha", value: false });
        this.$refs.senha.focus();
      }
      if (this.form.usuario.trim() == "") {
        this.validator.push({ key: "usuario", value: false });
        this.$refs.usuario.focus();
      }

      if (this.validator.length <= 0) {
        this.loading = true;
        console.log("try login");
        this.$store
          .dispatch("auth/auth", { tipo: "sistema", ...this.form })
          .then((data) => {
            console.log("result login", data);
            if (!data.success) {
              this.$swal.fire({
                title: "Usuário e/ou senha inválido(s)!",
                icon: "error",
              });
              this.$store.state.loading = false;
              this.loading = false;
            } else {
              console.log(
                "links",
                this.$store.state.menu.filter((x) =>
                  x.permissao.includes(data.user.user.tipo)
                )
              );
              if (
                this.$store.state.menu.filter((x) =>
                  x.permissao.includes(data.user.user.tipo)
                ).length <= 0
              ) {
                this.$swal.fire({
                  title: "Usuário sem permissão para acessar o sistema!",
                  icon: "error",
                });
                this.$store.state.loading = false;
                this.loading = false;
                return;
              }
            
              this.$router.push(
                `${
                  this.$store.state.menu.filter((x) =>
                    x.permissao.includes(data.user.user.tipo)
                  )[0].link
                }`
              );
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      }

      return;
    },
    validateState(ref) {
      const fd = this.validator.find((x) => x.key === ref);
      return !fd ? null : fd.value;
    },
  },
};
</script>
